import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import './style.css';
import awsExports from './aws-exports';
import { useNavigate } from 'react-router-dom';

Amplify.configure(awsExports);
const App = ({ signOut, user }) => {
  const navigate = useNavigate(); // useNavigate hook for navigation
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);
  const [testId, setTestId] = useState("");
  const [jira_ticket, setJiraTicket] = useState("");
  const [app_developer, setAppDeveloper] = useState("");
  const [testDetails, setTestDetails] = useState("");
  const [client_app_name, setPriority] = useState("Select client app");
  const [app_name, setAppName] = useState("Select client app");
  const [run_test, setRunTest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);

  const handleInputChange = setter => e => {
    setter(e.target.value);
    setIsFormModified(true);  // Form has been modified
  };
  const handleCheckboxChange = setter => e => {
    setter(e.target.checked);
    setIsFormModified(true);  // Form has been modified
  };

  const submitTestCase = () => {
    setIsLoading(true);
    const data = {
      testId,
      date,
      jira_ticket,
      app_developer,
      testDetails,
      client_app_name,
      app_name,
      run_test
    };

    // Define requestOptions including method, headers, and body
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ body: JSON.stringify(data) }), // Assuming you need to stringify your data like this
    };

    fetch("https://zaismxsm79.execute-api.us-east-1.amazonaws.com/test/submit", requestOptions)
      .then(response => response.json())
      .then(result => {
        localStorage.setItem('submissionMessage', result.body); // Assuming 'result.body' is where your message is
        navigate('/thank-you'); // Navigate to the Thank You page
        setIsLoading(false);
        setIsFormModified(false); // Reset form modification flag
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        alert('There was an error submitting the form. Please try again.');
        setIsLoading(false); // Ensure loading state is reset on error
      });
};

return (
  <div className="App">
    <h1>Welcome to automated Test Submission</h1> 
    <button className="signOutButton" onClick={signOut}>Sign out</button>

    <div className="container">
      <div className="header">
        <img src="./banner.png" alt="Top Left Image" className="top-left-image" />
      </div>
      <h2>Submit Test Case</h2>
      <form action="#" id="testCaseForm" onSubmit={e => { e.preventDefault(); submitTestCase(); }}>
        <label htmlFor="testId">test_id:</label>
        <input type="text" id="testId" value={testId} onChange={handleInputChange(setTestId)} required />
        
        <label htmlFor="date">test_date:</label> 
        <input type="date" id="date" value={date} onChange={handleInputChange(setDate)} required />

        <label htmlFor="jira_ticket">jira_ticket:</label>
        <input type="text" id="jira_ticket" value={jira_ticket} onChange={handleInputChange(setJiraTicket)} required />

        <label htmlFor="app_developer">app_developer:</label>
        <input type="text" id="app_developer" value={app_developer} onChange={handleInputChange(setAppDeveloper)} required />

        <label htmlFor="testDetails">Test Details:</label>
        <textarea id="testDetails" value={testDetails} onChange={handleInputChange(setTestDetails)}></textarea>

        <label htmlFor="client_app_name">client_app_name:</label>
        <select id="client_app_name" value={client_app_name} onChange={handleInputChange(setPriority)}>
            <option value="Select client app" disabled>Select client app</option>
            <option value="ROKU">ROKU</option>
            <option value="AndroidTV">AndroidTV</option>
            <option value="AndroidMobile">AndroidMobile</option>
            <option value="AppleMobile">AppleMobile</option>
            <option value="AppleTV">AppleTV</option>
            <option value="Lightning">Lightning</option>
            <option value="Lg">LG</option>
            {/* ... other options ... */}
        </select>
        <label htmlFor="app_name">app_name:</label>
        <select id="app_name" value={app_name} onChange={handleInputChange(setAppName)}>
            <option value="Select client app" disabled>Select app</option>
            <option value="KTV">KTV</option>
            <option value="GlitchPlus">GlitchPlus</option>
            <option value="DudePerfect">DudePerfect</option>
        </select>

        <label htmlFor="run_test">run_tests</label>
        <input type="checkbox" id="run_test" checked={run_test} onChange={e => { setIsFormModified(true); setRunTest(e.target.checked); }} required />

        <div className="button-group">
        <button type="reset" onClick={() => {
          setTestId("");
          setDate("");
          setJiraTicket("");
          setAppDeveloper("");
          setTestDetails("");
          setPriority("");
          setAppName("");
          setRunTest(false);
          setIsFormModified(false);
        }}>Clear</button>
            <input type="submit" value="Submit" disabled={isLoading || !isFormModified} />
        </div>
      </form>
      {isLoading && <p>Submitting your request, please wait...</p>}  {/* Display message when loading */}
    </div>
  </div>
);
};

export default withAuthenticator(App);
