// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import ThankYouPage from './ThankYouPage'; // Import the ThankYouPage component
import { BrowserRouter, Routes, Route } from 'react-router-dom'; 

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} /> {/* Default route for App */}
        <Route path="/thank-you" element={<ThankYouPage />} /> {/* Route for Thank You page */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
