// ThankYouPage.js

import React from 'react';

const ThankYouPage = () => {
    // Retrieve the message from localStorage
    const message = localStorage.getItem('submissionMessage');
    localStorage.removeItem('submissionMessage'); // Clean up after retrieving

    return (
        <div>
            <h1>Thank You for Your Submission!</h1>
            <p>{message || 'We will get back to you soon.'}</p>
        </div>
    );
};

export default ThankYouPage;
